*{
  margin: 0;
  padding:0;
  outline:0;
  box-sizing: border-box;
}
p{
  margin-bottom: 0;
}
html, border-style, #root {
  height: 100%;
} 
body{
  /* font: 14px 'Montserrat', sans-serif; */
  font-family: 'Urbanist', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale;
}
ul{
  list-style: none;
}
button{
  cursor: pointer;
}